<template>
  <form @submit.prevent="save()">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="text">Name</label>
          <input type="text" class="form-control" name="text" v-model.trim="form.text" required />
        </div>
      </div>
      <div class="col-12">
        <h6>Studios</h6>
      </div>
      <div class="col-12">
        <div class="form-group form-check" v-for="studio in studios" :key="studio.id">
          <input
            type="checkbox"
            class="form-check-input"
            :id="studio.id"
            v-model="form.studios"
            :value="studio.id"
          />
          <label class="form-check-label" :for="studio.id">{{studio.name}}</label>
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-dark" :disabled="submitting">Speichern</button>
    <div class="alert alert-danger mt-3" v-if="errMsg">{{errMsg}}</div>
  </form>
</template>

<script>
import { firestore } from '../firebase';

export default {
  data() {
    return {
      broadcast: null,
      form: {
        text: '',
        studios: []
      },
      submitting: false,
      studios: [],
      errMsg: ''
    };
  },
  async created() {
    const id = this.$route.params.id;

    const studiosSnapshot = await firestore
      .collection('studios')
      .orderBy('nr')
      .get();

    this.studios = studiosSnapshot.docs.map(snapshot => {
      return {
        id: snapshot.id,
        ...snapshot.data()
      };
    });

    if (id) {
      const broadcastSnapshot = await firestore
        .collection('broadcasts')
        .doc(id)
        .get();

      this.broadcast = {
        id: broadcastSnapshot.id,
        ...broadcastSnapshot.data()
      };
      this.form = { ...this.broadcast };

      delete this.form.id;
    }
  },
  methods: {
    async save() {
      this.submitting = true;
      this.errMsg = '';

      try {
        if (this.broadcast) {
          await firestore
            .collection('broadcasts')
            .doc(this.broadcast.id)
            .update({
              ...this.form
            });
        } else {
          await firestore.collection('broadcasts').add({
            ...this.form
          });
        }

        this.$router.push({
          name: 'broadcasts'
        });
      } catch (err) {
        this.errMsg = err.message;

        throw err;
      } finally {
        this.submitting = false;
      }
    }
  }
};
</script>

